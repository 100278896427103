import omit from 'lodash/omit'
import { useSnackbar } from 'notistack'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

import { networksApi } from 'api/networks-api'

export const NETWORK_ACTION = {
  CREATE: 'create',
  UPDATE: 'update',
  REMOVE: 'remove',
}

export const useNetworksApi = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: ['networks'],
    queryFn: () => networksApi.get(),
    cacheTime: 60000 * 60, // 1 hour
    staleTime: 60000 * 60, // 1 hour
    placeholderData: [],
  })

  const save = useMutation({
    mutationFn: (data) => {
      return networksApi.save(omit(data, 'id'))
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['networks'])
      enqueueSnackbar(`network ${data.name} ${data.id ? 'updated' : 'created'}`, { variant: 'success' })
    },
    onError: (e) => {
      enqueueSnackbar(e?.message || 'Unknown error', { variant: 'error' })
    },
  })

  const remove = useMutation({
    mutationFn: async (networkId, networkName) => {
      const res = await networksApi.remove(networkId)

      return { networkName, networkId, error: res.error }
    },
    onSuccess: (data) => {
      if (data.error) {
        enqueueSnackbar(data.error || 'Unknown error', { variant: 'error' })
      } else {
        queryClient.invalidateQueries(['networks'])
        enqueueSnackbar(`network ${data.networkName} removed`, { variant: 'success' })
      }
    },
    onError: (e) => {
      enqueueSnackbar(e || 'Unknown error', { variant: 'error' })
    },
  })

  return [query, { save, remove }]
}
