import { Backdrop, Fade, Modal, Paper, Tab, Tabs } from '@mui/material'
import Button from 'components/Button/Button'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { set, cloneDeep } from 'lodash'

import { useExchangesListSelector, useNetworksSelector } from 'redux/selectors/settings'
import arbitrageAPI from 'api/arbitrage-api'
import botAPI from 'api/bot-api'
import useDebounce from 'hooks/use-debounce'

import s from './BotEditor.module.css'
import { BotEditorSimple } from './BotEditorSimple'
import { BotEditorTriple } from './BotEditorTriple'
import { BalanceLeveling } from './BalanceLeveling'

const INITIAL_BOT = {
  classic: {
    cex_symbol_left: '',
    cex_symbol_right: '',
    dex_symbol_left: '',
    dex_symbol_right: '',
    exchange_from: '',
    exchange_to: '',
    amount: {},
    pmin: '',
    cex_multiplier: '1',
    pool: '',
  },
  triple: {
    amount: {},
    pmin: '',
    cex_multiplier: '1',
    step1: {
      exchange: '',
      network: '',
      pool: '',
      left: '',
      right: '',
    },
    step2: {
      exchange: {
        id: '1',
        name: 'Binance',
      },
      left: '',
      right: '',
    },
    step3: {
      exchange: {
        id: '1',
        name: 'Binance',
      },
      left: '',
      right: '',
    },
  },
}

const getInitialBotValues = (type, exchangesOptions) => {
  const values = { ...INITIAL_BOT[type] }
  const binanceCex = exchangesOptions.find(({ id }) => id === 1)

  if (type === 'triple') {
    values.step2.exchange = binanceCex
    values.step3.exchange = binanceCex
  }

  return values
}

const fromClassisToTriple = (classic, networks) => {
  return {
    ...INITIAL_BOT.triple,
    pmin: classic.pmin,
    cex_multiplier: classic.cex_multiplier,
    step1: {
      ...INITIAL_BOT.triple.step1,
      exchange: classic.exchange_from,
      network: networks[classic.exchange_from.network],
      left: classic.dex_symbol_left,
      right: classic.dex_symbol_right,
      pool: classic.classic_arbitrage[0].dex.pool,
    },
    step2: {
      ...INITIAL_BOT.triple.step2,
      exchange: classic.exchange_to,
      left: classic.cex_symbol_left,
      right: classic.cex_symbol_right,
    },
    step3: {
      ...INITIAL_BOT.triple.step3,
      exchange: classic.exchange_to,
      left: classic.cex_symbol_left,
      right: classic.cex_symbol_right,
    },
  }
}

const fromTripleToClassic = (triple) => {
  return {
    ...INITIAL_BOT.classic,
    pmin: triple.pmin,
    cex_multiplier: triple.cex_multiplier,
    cex_symbol_left: triple.step2.left,
    cex_symbol_right: triple.step2.right,
    dex_symbol_left: triple.step1.left,
    dex_symbol_right: triple.step1.right,
    exchange_from: triple.step1.exchange,
    exchange_to: triple.step2.exchange,
    pool: triple.step1.pool,
  }
}

const switchBotTypeFn = {
  triple: fromClassisToTriple,
  classic: fromTripleToClassic,
}

const parseRawClassic = (fill, exchangesOptions, networks) => {
  return {
    ...fill,
    exchange_from: exchangesOptions.find(
      ({ internal_name, network_name }) =>
        internal_name === fill.exchange_from.name && network_name === fill.exchange_from.network
    ),
    network: networks?.[fill.exchange_from.network],
    exchange_to: exchangesOptions.find(({ internal_name }) => internal_name === fill.exchange_to.name),
    amount: fill.classic_arbitrage.map(({ amount, profit, side }) => ({
      amount,
      profit,
      direction: side,
    })),
    pool: fill.classic_arbitrage[0].dex.pool,
  }
}

const parseRawTriple = (fill, exchangesOptions, networks) => {
  const arb = fill.triple_arbitrage[0] || {}

  return {
    ...fill,
    ...arb,
    amount: fill.triple_arbitrage.map(({ amount, profit, side }) => ({
      amount,
      profit,
      direction: side,
    })),
    step1: {
      ...arb.step1,
      network: arb?.step1?.network ? networks[arb.step1.network] : '',
      exchange: exchangesOptions.find(({ internal_name }) => internal_name === arb.step1.exchange),
    },
    step2: {
      ...arb.step2,
      exchange: exchangesOptions.find(({ internal_name }) => internal_name === arb.step2.exchange),
    },
    step3: {
      ...arb.step3,
      exchange: exchangesOptions.find(({ internal_name }) => internal_name === arb.step3.exchange),
    },
  }
}

const formDataToApiPayload = {
  classic: (values) => ({
    type: 'CLASSIC',
    pmin: values.pmin,
    cex_multiplier: values.cex_multiplier,
    classic_arbitrage: Object.values(values.amount).map(({ direction, amount, profit }) => ({
      amount: amount,
      profit: profit,
      side: direction,
      dex: {
        exchange: values.exchange_from.id.toString(),
        network: values.network.id.toString(),
        pool: values.pool,
        left: values.dex_symbol_left,
        right: values.dex_symbol_right,
      },
      cex: {
        exchange: values.exchange_to.id.toString(),
        left: values.cex_symbol_left,
        right: values.cex_symbol_right,
      },
    })),
  }),
  triple: (values) => ({
    type: 'TRIPLE',
    pmin: values.pmin,
    cex_multiplier: values.cex_multiplier,
    triple_arbitrage: Object.values(values.amount).map(({ direction, amount, profit }) => ({
      amount: amount,
      profit: profit,
      side: direction,
      step1: {
        ...values.step1,
        network: values.step1.network.id.toString(),
        exchange: values.step1.exchange.id.toString(),
      },
      step2: {
        ...values.step2,
        exchange: values.step2.exchange.id.toString(),
      },
      step3: {
        ...values.step3,
        exchange: values.step3.exchange.id.toString(),
      },
    })),
  }),
}

const BotEditor = ({ id, show, fill, onClose, setPopup, refreshBots, archived }) => {
  const exchangesOptions = useExchangesListSelector()
  const networks = useNetworksSelector()

  const dexExchanges = useMemo(() => exchangesOptions.filter(({ is_dex }) => is_dex), [exchangesOptions])
  const cexExchanges = useMemo(() => exchangesOptions.filter(({ is_dex }) => !is_dex), [exchangesOptions])

  const [pairQuery, setPairQuery] = useState('')
  const [, setPairsOptions] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  const [tab, setTab] = useState('classic')
  const initialBot = INITIAL_BOT[tab]
  const [values, setValues] = useState(initialBot)

  const debouncedPairQuery = useDebounce(pairQuery, 400)

  useEffect(() => {
    if (!debouncedPairQuery) {
      return
    }
    ; (async () => {
      const data = await arbitrageAPI.getPairs(debouncedPairQuery)

      setPairsOptions(data?.data)
    })()
  }, [debouncedPairQuery])

  useEffect(() => {
    if (fill) {
      if (fill.type.toLowerCase() === 'classic') {
        setTab('classic')
        setValues(parseRawClassic(fill, exchangesOptions, networks))
      } else {
        setTab('triple')
        setValues(parseRawTriple(fill, exchangesOptions, networks))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveBot = async () => {
    setIsSaving(true)

    try {
      if (id) values.id = id
      let response

      const payload = formDataToApiPayload[tab](values)

      const amountKeys = Object.keys(values.amount)

      const isAmountNotAdded = !amountKeys.length
      const isAmountNotValid = amountKeys.some((key) => !values.amount[key].amount || !values.amount[key].profit)

      if (isAmountNotAdded || isAmountNotValid) {
        setPopup((prev) => ({
          ...prev,
          open: true,
          message: isAmountNotAdded
            ? 'Validation error. You must add at least one buy or sell direction'
            : 'Validation error. All Amount and Profit fields should not be empty',
          severity: 'error',
        }))

        return
      }

      if (fill) {
        response = await botAPI.updateBot(id, tab.toUpperCase(), payload)
      } else {
        response = await botAPI.createBot(tab.toUpperCase(), payload)
      }

      if (response.status === 200) {
        setPopup((prev) => ({
          ...prev,
          open: true,
          message: 'Bot saved successfully!',
          severity: 'success',
        }))
        onClose()
        refreshBots()
        setValues(initialBot)
      } else {
        if (typeof response.data === 'string') {
          throw Error(response.data)
        }
        let errors = ''

        for (let key of Object.keys(response.data)) {
          if (response.data?.[key] !== undefined) {
            errors += `${response.data[key]}\n`
          }
        }

        throw Error(errors)
      }
    } catch (err) {
      console.error(err)
      setPopup((prev) => ({ ...prev, open: true, message: err.message, severity: 'error' }))
    } finally {
      setIsSaving(false)
    }
  }

  const handleChange = (e) => {
    setValues((prev) => {
      const newState = cloneDeep(prev)

      set(newState, e.target.name, e.target.value)

      if (!prev.step1?.network?.id && e.target.name === 'step1.exchange') {
        set(newState, 'step1.network', networks[e.target.value.network])
      }

      if (e.target.name === 'step1.network') {
        set(newState, 'step1.exchange', '')
      }

      return newState
    })
  }

  const handlePairQueryChange = (value) => setPairQuery(value)

  const createAmountFields = (direction) => {
    setValues((prev) => {
      const keys = Object.keys(prev.amount).length > 0 ? Object.keys(prev.amount) : [0]
      const maxKey = Math.max(...keys)

      return { ...prev, amount: { ...prev.amount, [maxKey + 1]: { direction: direction } } }
    })
  }

  const changeAmountState = (e, index, key) => {
    setValues((prev) => ({
      ...prev,
      amount: { ...prev.amount, [index]: { ...prev.amount[index], [key]: e.target.value } },
    }))
  }

  const handleClose = () => {
    onClose()
    setValues(initialBot)
  }

  const handleTabChange = useCallback(
    (_, newTab) => {
      setTab(newTab)

      if (fill && newTab !== 'balanceLeveling') {
        const data =
          fill.type.toLowerCase() === 'classic'
            ? parseRawClassic(fill, exchangesOptions, networks)
            : parseRawTriple(fill, exchangesOptions, networks)

        if (fill.type.toLowerCase() === newTab) {
          setValues(data)
        } else {
          setValues(switchBotTypeFn[newTab](data, networks))
        }
      } else {
        setValues(getInitialBotValues(newTab, exchangesOptions))
      }
    },
    [setTab, setValues, fill, exchangesOptions, networks]
  )

  return (
    <>
      <Modal
        aria-labelledby='modal-graph'
        aria-describedby='modal-graph'
        className={s.modal}
        open={show}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <Paper className={s.settings} id='bot_editor_popup'>
            <div className='flex-row align-center justify-between' style={{ marginBottom: 8 }}>
              <h4>{id ? `Edit a bot #${id}` : 'Create a bot'}</h4>
              <i className={s.closeButton + ' fas fa-times'} onClick={handleClose} id='close_icon' />
            </div>
            <Tabs className={s.tabButtons} value={tab} onChange={handleTabChange}>
              <Tab label='classic' value='classic' id='classic_tab' />
              <Tab label='triple' value='triple' id='triple_tab' />
              {id && <Tab label='Balance Leveling' value='balanceLeveling' id='balanceLeveling_tab' />}
            </Tabs>
            {tab === 'classic' && (
              <BotEditorSimple
                values={values}
                handleChange={handleChange}
                archived={archived}
                handlePairQueryChange={handlePairQueryChange}
                changeAmountState={changeAmountState}
                createAmountFields={createAmountFields}
                setValues={setValues}
                dexExchanges={dexExchanges}
                cexExchanges={cexExchanges}
                networks={networks}
              />
            )}
            {tab === 'triple' && (
              <BotEditorTriple
                values={values}
                handleChange={handleChange}
                archived={archived}
                handlePairQueryChange={handlePairQueryChange}
                changeAmountState={changeAmountState}
                createAmountFields={createAmountFields}
                setValues={setValues}
                dexExchanges={dexExchanges}
                cexExchanges={cexExchanges}
                networks={networks}
              />
            )}
            {tab === 'balanceLeveling' && id && <BalanceLeveling botId={id} refreshBots={refreshBots} />}

            {tab !== 'balanceLeveling' && (
              <div className={s.saveBtn}>
                <Button
                  disabled={isSaving || archived}
                  onClick={saveBot}
                  variant={'outlined'}
                  color='inherit'
                  id='save_bot_button'
                >
                  Save
                </Button>
              </div>
            )}
          </Paper>
        </Fade>
      </Modal>
    </>
  )
}

export default BotEditor
